<template>
  <div class="pa-4">
    <customer-staffs
      v-if="$store.state.user.role.startsWith('customer')"
      :item="$store.state.userOrg"
    />
    <center-staffs
      v-if="$store.state.user.role.startsWith('center')"
      :item="$store.state.userOrg"
    />
    <reception-staffs
      v-if="$store.state.user.role.startsWith('reception')"
      :item="$store.state.userOrg"
    />
  </div>
</template>

<script>

import CustomerStaffs from '@/components/CustomerStaffs.vue';
import CenterStaffs from '@/components/CenterStaffs.vue';
import ReceptionStaffs from '@/components/ReceptionStaffs.vue';


export default {
  name: 'Staffs',
  components: {
    CustomerStaffs,
    CenterStaffs,
    ReceptionStaffs
  },
  data() {
    return {
      userDialogModel: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '210px',
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'email',
          width: '160px',
        },
        {
          text: '氏名',
          align: 'left',
          sortable: true,
          value: 'name',
          width: '140px',
        },
        {
          text: 'ふりがな',
          align: 'left',
          sortable: true,
          value: 'name_kana',
          width: '140px',
        },
        {
          text: '電話番号',
          align: 'left',
          sortable: true,
          value: 'phone_number',
          width: '120px',
        },
        {
          text: '権限の種別',
          align: 'left',
          sortable: true,
          value: 'role',
        },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'created',
          width: '100px',
        },
      ],
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      items: [],
      currentItem: null,
      mode: 'none',
      recordDialogTitle: '登録',
      configRobots: {},
      modelNumbers: [],
      searchSelectedField: 'Emailアドレス',
      searchFields: ['Emailアドレス', '氏名', '氏名（ふりがな）', '電話番号', '権限の種別', '備考'],
      searchKeyword: '',
      accessTypeModel: 'all',

    };
  },
  computed: {
    logDateFormatted() {
      return this.logDate.toLocaleDateString().replace(/\//g, '-');
    }
  },
  methods: {
  },
  mounted() {
    console.log('Staffs: user, userOrg', this.$store.state.user, this.$store.state.userOrg);
  },

}
</script>

<style scoped>
.theme-color {
  color: white;
  background-color:  #B3131A;
}

.top-rounded {
  margin:0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>